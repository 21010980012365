<template>
  <v-form>
    <form-subheader title="Coordinates Source 1" class="pt-4" />
    <v-select
      v-model="location[0].sourcePropertyId"
      :items="properties"
      :disabled="!properties.length"
      clearable
      item-text="name"
      item-value="name"
      label="Positioning"
      @input="sendData"
    />
    <form-subheader title="Coordinates Source 2" />
    <v-select
      v-model="location[1].sourcePropertyId"
      :items="properties"
      :disabled="!properties.length"
      clearable
      item-text="name"
      item-value="name"
      label="Positioning"
      @input="sendData"
    />
    <form-subheader title="Coordinates Source 3" />
    <v-select
      v-model="location[2].sourcePropertyId"
      :items="properties"
      :disabled="!properties.length"
      clearable
      item-text="name"
      item-value="name"
      label="Positioning"
      @input="sendData"
    />
  </v-form>
</template>

<script>
import schemas from '@/modules/objects/api/object-from-devices.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { onMounted, reactive, toRefs } from '@vue/composition-api';

export default {
  name: 'LocationTab',
  props: {
    data: { type: Object, default: () => ({}) }
  },
  setup(props, { emit }) {
    const localState = reactive({
      location: [
        {
          priority: 1,
          sourcePropertyId: ''
        },
        {
          priority: 2,
          sourcePropertyId: ''
        },
        {
          priority: 3,
          sourcePropertyId: ''
        }
      ]
    });
    const fetchDriver = useLazyQuery(
      schemas.fetchSpacialProperties,
      {
        id: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const initData = () => {
      const schemaDriverId = props.data['general'].objectType;

      if (schemaDriverId) {
        fetchDriver.variables.value.id = schemaDriverId;
        fetchDriver.load();
      }
    };

    const properties = useResult(fetchDriver.result, [], data =>
      data.schema.schemaProperties.map(item => ({
        id: item.id,
        name: `${item.groupName}/${item.property}`
      }))
    );

    const sendData = () => {
      emit('update-form', {
        key: 'location',
        data: localState.location.filter(item => item.sourcePropertyId)
      });
    };

    onMounted(() => {
      initData();
    });

    return {
      ...toRefs(localState),
      sendData,
      properties
    };
  }
};
</script>
